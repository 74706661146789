<script>
    import { getContext, onMount } from 'svelte';
    import Select from 'svelte-select';
    import { push } from 'svelte-spa-router';

    import user from 'stores/user';
    import { getCountries } from 'services/user';
    import shop from 'stores/shop';
    import shoppingCart from 'stores/shoppingCart';
    import db from 'services/db';

    import Button from 'components/Button';

    const { close } = getContext('simple-modal');

    let countries = [];
    let selectedCountry = $user.country ? { label: $user.country.displayName, value: $user.country.id } : null;

    onMount(() => {
        loadCountries();
    });

    async function loadCountries() {
        getCountries().then((data) => {
            countries = data;
        });
    }

    function getCountryOptions() {
        return countries.map((country) => {
            return {
                label: country.displayName,
                value: country.id,
            };
        });
    }

    function handleClick() {
        let country = countries.find((country) => country.id === selectedCountry.value);
        user.setCountry(country);

        shop.fetch(selectedCountry.value).then(() => {
            shoppingCart.clear();
            push('/');
            close();
        });
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.modal-content {
  margin-bottom: 3.75em;
  padding: 2.8125em 2.8125em 0 2.8125em;
}
.modal-content__title {
  margin-bottom: 0.625em;
  font-size: 1.125em;
  font-weight: 700;
  color: #5123ad;
}
.modal-content__desc {
  font-size: 0.875em;
  color: #999999;
}

.country-select {
  margin: 2.5em 0;
  font-size: 0.875em;
}
.country-select__label {
  margin-bottom: 0.5em;
  display: block;
  font-size: 0.875em;
}

.actions {
  padding: 0.625em 1.5625em;
  background: #f4f4f4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}</style>

<div class="modal">
    <div class="modal-content">
        {#if !$user.country}
            <h2 class="modal-content__title">Welcome to the Term Finance Shop</h2>
            <p class="modal-content__desc">
                Our Term Finance shop offers you a great variety of products from various retailers, with the ability to
                finance your purchases over time.
            </p>
        {:else}
            <h2 class="modal-content__title">Leaving {$user.country.displayName}?</h2>
            <p class="modal-content__desc">
                Please note that available retailers, currency and pricing will differ by each Country. Contact us if
                you have any questions.
            </p>
        {/if}

        <div class="country-select">
            <label class="country-select__label">
                To get started,
                <strong>select your Country</strong>
                from the list below:
            </label>
            <Select
                items={countries ? getCountryOptions() : []}
                bind:selectedValue={selectedCountry}
                isDisabled={!countries.length}
                placeholder={countries ? 'Select a Country ...' : 'Loading Countries ...'} />
        </div>
    </div>
    <div class="actions">
        {#if $user.country}
            <Button
                primary
                cancel
                onClick={() => {
                    close();
                }}>
                Cancel
            </Button>
        {/if}
        <Button primary disabled={!selectedCountry} onClick={handleClick}>Start Shopping!</Button>
    </div>
</div>
