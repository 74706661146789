<script>
    import { getContext } from 'svelte';
    import { push, link } from 'svelte-spa-router';

    import user from 'stores/user';
    import shoppingCart from 'stores/shoppingCart';
    import { getLoanApplicationUrl } from 'services/loan';
    import { placeOrder } from 'services/shop';

    import LOANS_CONFIG from 'configs/loans';
    import ORDERS_CONFIG from 'configs/orders';

    import Nav from 'components/Nav';
    import Page from 'components/Page';
    import Header from 'components/Header';
    import SplitLayout from 'components/SplitLayout';
    import ShoppingCartBreakdown from 'components/ShoppingCartBreakdown';
    import CheckoutForm from 'components/CheckoutForm';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';
    import { ConfirmModal, confirmModalOptions } from 'components/ConfirmModal';

    const { open } = getContext('simple-modal');

    function displayAlertModal(title, message, onClose = () => {}) {
        open(
            AlertModal,
            {
                title,
                message,
                onClose,
            },
            alertModalOptions,
        );
    }

    function isValid() {
        return (
            $shoppingCart.customer.firstName &&
            $shoppingCart.customer.lastName &&
            $shoppingCart.customer.emailAddress &&
            $shoppingCart.customer.address.location &&
            $shoppingCart.customer.address.street
        );
    }

    function handleCheckout(event) {
        if (!isValid()) {
            displayAlertModal(
                'Uh oh! You forgot to fill in some details.',
                'Please be sure to fill in your first name, last name, email address and address before trying again.',
            );

            return;
        }

        open(
            ConfirmModal,
            {
                title: `Are you ready to checkout, ${$shoppingCart.customer.firstName}?`,
                message: 'Please make sure that all of your information is correct before continuing.',
                onConfirm: checkout,
            },
            confirmModalOptions,
        );
    }

    function checkout() {
        placeOrder($user, $shoppingCart).then(
            () => {
                let onClose = () => {
                    let { firstName, lastName, emailAddress, phoneNumber, isExistingCustomer } = $shoppingCart.customer;

                    let loanApplicationUrl = getLoanApplicationUrl(
                        $user.country.infinityId || LOANS_CONFIG.infinityIds[$user.country.id],
                        $shoppingCart.totalCost,
                        firstName,
                        lastName,
                        emailAddress,
                        phoneNumber,
                    );

                    if (isExistingCustomer || $shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.creditCard) {
                        push('/');
                    } else {
                        window.location.replace(loanApplicationUrl);
                    }

                    shoppingCart.clear();
                };

                let message;
                if ($shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.creditCard) {
                    message = `<p>You order has been placed successfully. An email confirmation with your order details has been sent to ${$shoppingCart.customer.emailAddress}.</p>
                    <p>A member of our team will be in touch to confirm your order and process your payment.</p>
                    <p>Thank you for choosing Term Finance!</p>`;
                } else {
                    message = `<p>Your order has been placed successfully.</p> <p>An email confirmation with your order details has been sent to ${$shoppingCart.customer.emailAddress}.</p>
                    <p>If you are not an existing Term Finance customer, you will be redirect to our internal loan  application form. Please fill out this application and wait for a member of our team to get in touch.</p>
                    <p>If you are an existing customer, you will be redirected to the homepage.</p> 
                    <p>Thank you for choosing Term Finance!</p>`;
                }

                setTimeout(() => {
                    displayAlertModal(`Thanks for your order ${$shoppingCart.customer.firstName}!`, message, onClose);
                }, 500);
            },
            () => {
                setTimeout(() => {
                    displayAlertModal(
                        'Uh oh! Something went wrong.',
                        'We ran into an issue while processing your order. Please try again.',
                    );
                }, 500);
            },
        );
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");</style>

<div class="root">
    <Nav />
    <Page>
        <div slot="header">
            <Header canSeeBack>
                <span slot="title">Checkout</span>
                <span slot="subtitle">
                    {`${$shoppingCart.numProducts} ${$shoppingCart.numProducts == 1 ? 'Product' : 'Products'} Ready for Checkout`}
                </span>
                <span slot="back">Review my Shopping Cart</span>
            </Header>
        </div>
        <div slot="content">
            <SplitLayout>
                <div slot="left">
                    <CheckoutForm />
                </div>
                <div slot="right">
                    <ShoppingCartBreakdown on:checkout={handleCheckout} />
                </div>
            </SplitLayout>
        </div>
    </Page>

</div>
