<script>
    import { getContext } from 'svelte';
    import { push } from 'svelte-spa-router';

    import user from 'stores/user';
    import shoppingCart from 'stores/shoppingCart';
    import { formatCurrency } from 'services/util';
    import { getRetailerDisplayName } from 'services/shop';

    import { ConfirmModal, confirmModalOptions } from 'components/ConfirmModal';
    import Counter from 'components/Counter';

    const { open } = getContext('simple-modal');

    export let product;

    function add(product) {
        shoppingCart.add(product);
    }

    function remove(product, removeAll = false) {
        if ($shoppingCart.numProducts == 1 || (removeAll && $shoppingCart.numProducts === product.quantity)) {
            open(
                ConfirmModal,
                {
                    title: 'Empty your cart?',
                    message: 'Removing this product will empty your cart and redirect you to the Products page.',
                    onConfirm: () => {
                        shoppingCart.remove(product, removeAll);
                        push('/');
                    },
                },
                confirmModalOptions,
            );
        } else {
            shoppingCart.remove(product, removeAll);
        }
    }

    function handleRemoveAll(product) {
        open(
            ConfirmModal,
            {
                title: 'Remove products from your cart?',
                message: `Are you sure you want to remove ${product.quantity} ${product.name} from your cart?`,
                onConfirm: () => {
                    setTimeout(() => {
                        remove(product, true);
                    }, 100);
                },
            },
            confirmModalOptions,
        );
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.product {
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.25em 0.3125em;
  border-bottom: 1px solid #f4f4f4;
}
@media (min-width: 600px) {
  .product {
    flex-flow: row nowrap;
    padding: 1.25em 1.5625em;
  }
}
.product__thumbnail {
  width: 50%;
  height: auto;
}
@media (min-width: 600px) {
  .product__thumbnail {
    width: 25%;
    min-width: 125px;
  }
}
.product__price {
  width: 50%;
  font-size: 1.5em;
  font-weight: 400;
}
.product__price--sale {
  color: #22773b;
}
@media (min-width: 600px) {
  .product__price {
    margin: 0 0.3125em;
  }
}
.product__quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
@media (min-width: 600px) {
  .product__quantity {
    margin: 0 0.3125em;
  }
}

.product-summary {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}
@media (min-width: 600px) {
  .product-summary {
    margin: 0 0.3125em 0 1.5625em;
  }
}
.product-summary__title {
  margin-bottom: 0.3125em;
  font-size: 1.125em;
  font-weight: 700;
}
.product-summary__retailer {
  margin-bottom: 0.9375em;
  font-size: 0.875em;
}
.product-summary__desc {
  color: #999999;
  font-size: 0.875em;
}

.product-actions {
  display: none;
}
@media (min-width: 600px) {
  .product-actions {
    width: 20%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #f5f4ff;
  }
}
.product-actions__info, .product-actions__remove {
  cursor: pointer;
  margin: 0 0.3125em;
}</style>

<div class="product">
    <div class="product__thumbnail">
        <img src={product.imageUrl ? product.imageUrl : '/images/default_product@2x.jpg'} alt="thumbnail" />
    </div>
    <div class="product-summary">
        <div class="product-summary__title">{product.name}</div>
        <div class="product-summary__retailer">{getRetailerDisplayName(product.retailerId)}</div>
        <div class="product-summary__desc">{product.specs}</div>
    </div>
    <div class="product__quantity">
        <Counter
            value={product.quantity}
            onDecrement={() => {
                remove(product);
            }}
            onIncrement={() => {
                add(product);
            }} />
    </div>
    <div class="product__price" class:product__price--sale={product.salePrice}>
        {formatCurrency(product.salePrice || product.price, $user.country.id, $user.country.currencyCode)}
    </div>
    <div class="product-actions">
        <div class="product-actions__remove" on:click={() => handleRemoveAll(product)}>
            <img src="/images/remove_icon@2x.svg" height="30" alt="remove" />
        </div>
    </div>
</div>
