<script>
    import { onDestroy } from 'svelte';
    import { fly } from 'svelte/transition';
    import { tweened } from 'svelte/motion';
    import { cubicOut } from 'svelte/easing';

    import { push } from 'svelte-spa-router';

    import shoppingCart from 'stores/shoppingCart';
    import user from 'stores/user';
    import { formatCurrency } from 'services/util';

    import Button from 'components/Button';
    import Link from 'components/Link';
    import Lottie from 'components/Lottie';

    const totalCost = tweened($shoppingCart.totalCost, {
        duration: 400,
        easing: cubicOut,
    });

    let numProducts = $shoppingCart.products.length;

    let unsubscribe = shoppingCart.subscribe((store) => {
        totalCost.set(store.totalCost);
        numProducts = store.numProducts;
    });

    function handleFinanceItClick() {
        push('/cart');
    }

    onDestroy(() => {
        unsubscribe();
    });
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.calculator {
  display: flex;
  position: relative;
  z-index: 200;
  overflow: hidden;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  margin-top: 50px;
  padding: 0.9375em;
  background: #f4f4f4;
  transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media (min-width: 600px) {
  .calculator {
    justify-content: flex-start;
    padding: 1.5625em;
  }
}

.calculator-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 0.9375em;
  font-size: 1em;
  font-weight: 900;
  color: #000;
}
@media (min-width: 600px) {
  .calculator-title {
    width: 25%;
    font-size: 1.125em;
  }
}
.calculator-title__icon {
  display: none;
  width: 40px;
  height: 40px;
  margin-right: 0.3125em;
}
@media (min-width: 600px) {
  .calculator-title__icon {
    display: block;
  }
}

.group {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-right: 0.9375em;
}
@media (min-width: 600px) {
  .group {
    width: 25%;
    padding: 0;
  }
}
.group__title {
  margin-bottom: 0.3125em;
  font-weight: 300;
  font-size: 0.875em;
}
.group__value {
  font-weight: 700;
  font-size: 0.875em;
  color: #5123ad;
}
@media (min-width: 600px) {
  .group__value {
    font-size: 1em;
  }
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}
@media (min-width: 600px) {
  .actions {
    width: 25%;
  }
}
.actions__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: #5123ad;
  color: #fff;
  display: inline-block;
  align-self: flex-end;
  font-size: 0.75em;
}
.actions__btn:hover {
  background: #471f98;
  box-shadow: 0px 0px 5px 6px rgba(81, 35, 173, 0.05);
}
.actions__btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.actions__btn--cancel {
  display: none;
}
@media (min-width: 600px) {
  .actions__btn--cancel {
    display: inline-block;
    background: transparent;
    color: #999999;
  }
  .actions__btn--cancel:hover {
    background: rgba(0, 0, 0, 0.05);
    box-shadow: none;
  }
}</style>

{#if numProducts > 0}
    <div class="calculator" in:fly={{ y: 200, duration: 500 }}>
        <div class="calculator-title">
            <div class="calculator-title__icon animate__animated animate__slideInLeft">
                <Lottie jsonPath="lottie/shopping-cart.json" loop={false} />
            </div>
            My Cart
        </div>
        <div class="group">
            <div class="group__title">Products</div>
            <div class="group__value">{numProducts} {numProducts == 1 ? 'Product' : 'Products'}</div>
        </div>
        <div class="group">
            <div class="group__title">Total Cost</div>
            <div class="group__value">
                {#if $user.country}{formatCurrency($totalCost, $user.country.id, $user.country.currencyCode)}{/if}
            </div>
        </div>
        <div class="actions">
            <button class="actions__btn" on:click={handleFinanceItClick}>Checkout</button>
            <button
                class="actions__btn actions__btn--cancel"
                on:click={() => {
                    shoppingCart.clear();
                }}>
                Clear Cart
            </button>
        </div>
    </div>
{/if}
