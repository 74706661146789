<script>
    import { getContext } from 'svelte';
    import Button from 'components/Button';

    export let title;
    export let message;
    export let confirmLabel = 'OK';
    export let onClose = () => {};

    const { close } = getContext('simple-modal');

    function handleClose() {
        onClose();
        close();

        // setTimeout(() => {
        //     close();
        // }, 500);
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.modal-content {
  margin-bottom: 3.75em;
  padding: 2.8125em 2.8125em 0 2.8125em;
}
.modal-content__title {
  margin-bottom: 1.5625em;
  font-size: 1.25em;
  font-weight: 700;
  color: #5123ad;
  line-height: 1.25;
}
.modal-content__desc {
  font-size: 1em;
  color: #999999;
  line-height: 1.25;
}
.modal-content :global(p) {
  margin-bottom: 1.5625em;
}

.actions {
  padding: 0.625em 1.5625em;
  background: #f4f4f4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}</style>

<div class="modal">
    <div class="modal-content">
        <h2 class="modal-content__title">{title}</h2>
        <p class="modal-content__desc">
            {@html message}
        </p>
    </div>
    <div class="actions">
        <Button primary onClick={handleClose}>{confirmLabel}</Button>
    </div>
</div>
