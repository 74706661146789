<script>
    import ProductGridItem from './ProductGridItem';

    export let products = [];
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.grid {
  overflow: hidden;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
  gap: 0.3125em;
}
@media (min-width: 600px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.25em;
  }
}
@media (min-width: 900px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.25em;
  }
}</style>

<div class="grid">
    {#each products as product, i}
        <ProductGridItem {product} />
    {/each}
</div>
