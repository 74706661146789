<script>
    import { link } from 'svelte-spa-router';

    export let href = '#';
    export let iconUrl;
    export let primary = true;
    export let primaryInverse = false;
    export let secondary = false;
    export let secondaryInverse = false;
    export let small = false;
    export let fullWidth = false;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.primary {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: #5123ad;
  color: #fff;
}
.primary:hover {
  background: #471f98;
  box-shadow: 0px 0px 5px 6px rgba(81, 35, 173, 0.05);
}
.primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primaryInverse {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: #fff;
  color: #5123ad;
}
.primaryInverse:hover {
  background: #f2f2f2;
  box-shadow: 0px 0px 5px 6px rgba(255, 255, 255, 0.05);
}
.primaryInverse:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  color: #5123ad;
  border: 1px solid #5123ad;
}
.secondary:hover {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05);
}
.secondary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.secondary:hover {
  box-shadow: none;
  background: #5123ad;
  color: #fff;
}

.secondaryInverse {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.secondaryInverse:hover {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05);
}
.secondaryInverse:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.small {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  color: #5123ad;
  border: 1px solid #5123ad;
  padding: 0.3125em 0.625em;
  font-size: 0.75em;
}
.small:hover {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05);
}
.small:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.small:hover {
  box-shadow: none;
  background: #5123ad;
  color: #fff;
}

.fullWidth {
  width: 100%;
  text-align: center;
}

img {
  display: none;
  margin-right: 10px;
  vertical-align: middle;
}
@media (min-width: 600px) {
  img {
    display: inline-block;
  }
}</style>

<a
    {href}
    use:link
    class:primary
    class:primaryInverse
    class:secondary
    class:secondaryInverse
    class:fullWidth
    class:small>
    {#if iconUrl}
        <img alt="icon" src={iconUrl} />
    {/if}
    <slot />
</a>
