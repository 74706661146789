<script>

</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.25em;
  background: #f7f7f7;
}
.footer__logo {
  opacity: 0.5;
  margin-bottom: 0.625em;
}
.footer__legal {
  width: 100%;
  font-size: 0.75em;
  text-transform: uppercase;
  text-align: center;
  color: #999999;
}</style>

<footer class="footer">
    <img src="images/footer_logo@2x.png" height="40" alt="logo" class="footer__logo" />
    <div class="footer__legal">Copyright© {new Date().getYear() + 1900} Term Finance Ltd.</div>
</footer>
