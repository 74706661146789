<script>
    import { onMount, getContext } from 'svelte';
    import { link, push } from 'svelte-spa-router';

    import authService from 'services/auth';

    import USERS_CONFIG from 'configs/users';

    import AnimatedGrid from 'components/AnimatedGrid';
    import Button from 'components/Button';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';

    const { open } = getContext('simple-modal');

    let username;
    let password;
    let isLoading = false;

    onMount(() => {
        isLoading = true;
    });

    firebase.auth().onAuthStateChanged(function (u) {
        if (u) {
            authenticate(u.uid);
        } else {
            isLoading = false;
        }
    });

    function authenticate(id, cb) {
        authService.authenticate(id).then((userProfile) => {
            isLoading = false;

            if (!userProfile) {
                // Do not let the user log in if we can't fetch a profile
                authService.logout().then(() => {
                    open(
                        AlertModal,
                        {
                            title: 'Uh oh! We were unable to log you in.',
                            message:
                                'Your permissions need to be updated. Please contact your site administrator to get access.',
                        },
                        alertModalOptions,
                    );
                });
            } else {
                setTimeout(() => {
                    if (userProfile.role == USERS_CONFIG.roles.retailerAdmin) {
                        push('/admin/products');
                    } else {
                        push('/admin/orders/pending');
                    }
                }, 100);
            }
        });
    }

    function handleLogin(e) {
        e.preventDefault();
        isLoading = true;

        firebase
            .auth()
            .signInWithEmailAndPassword(username, password)
            .then(function (data) {
                authenticate(data.user.uid);
            })
            .catch(function (error) {
                open(
                    AlertModal,
                    {
                        title: 'Uh oh! We were unable to log you in.',
                        message: error.message,
                    },
                    alertModalOptions,
                );
            })
            .finally(() => {
                isLoading = false;
            });
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;
  flex: 1;
  height: 100vh;
  background: #fff;
  box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.35);
}

.right {
  flex: 1;
  height: 100vh;
  background: url("/images/admin_bg@2x.jpg") no-repeat center;
  background-size: cover;
}

.login {
  padding: 3.125em;
}
.login__title {
  margin-top: 4.375em;
  margin-bottom: 1.875em;
  font-weight: 700;
  font-size: 1.5em;
  color: #000;
}
.login__subtitle {
  margin: 1.5625em 0 3.125em 0;
  font-weight: 400;
  color: #999999;
}
.login__link {
  display: inline-block;
  margin-top: 10.9375em;
  color: #000;
}
.login__link:hover {
  color: #5123ad;
}

.login-form__inputs {
  margin-bottom: 1.5625em;
}
.login-form__input {
  width: 500px;
  height: 65px;
  border: 1px solid #f5f4ff;
  outline: 0;
  margin: 0;
  padding: 0.625em;
  font-size: 1em;
  color: #5123ad;
  font-weight: 700;
  transition: 0.25s all ease-in;
}
.login-form__input:focus {
  border-left: 6px solid #5123ad;
}
.login-form__btn {
  width: 250px;
}
.login-form input::-moz-placeholder {
  font-weight: 400;
}
.login-form input:-ms-input-placeholder {
  font-weight: 400;
}
.login-form input::-ms-input-placeholder {
  font-weight: 400;
}
.login-form input::placeholder {
  font-weight: 400;
}</style>

<div class="root">
    <div class="left">
        <div class="login">
            <div class="login__logo">
                <img src="/images/logo_big@2x.svg" height="69" alt="logo" />
                <h2 class="login__title">
                    Offering loans to individuals and businesses in a transparent, affordable and convienient way.
                </h2>
                <h3 class="login__subtitle">Welcome back. Please login to your account.</h3>
                <form class="login-form">
                    <div class="login-form__inputs">
                        <input
                            bind:value={username}
                            type="text"
                            name="username"
                            class="login-form__input"
                            placeholder="Email Address" />
                        <input
                            bind:value={password}
                            type="password"
                            name="password"
                            class="login-form__input"
                            placeholder="Password" />
                    </div>
                    <div class="login-form__btn">
                        <Button
                            onClick={handleLogin}
                            {isLoading}
                            primary
                            fullWidth
                            disabled={!username || !password || isLoading}>
                            {isLoading ? 'Logging in' : 'Login'}
                        </Button>
                    </div>
                    <a use:link href="/" class="login__link">Back to Shop</a>
                </form>
            </div>
        </div>
    </div>
    <div class="right">
        <AnimatedGrid opacity="0.1" />
    </div>
</div>
