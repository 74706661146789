<script>
    import { onMount, onDestroy } from 'svelte';

    import user from 'stores/user';
    import shop from 'stores/shop';
    import {
        getProductsForRetailers,
        getFeaturedProducts,
        getFilteredProductsForRetailers,
        getProductsBySearchQuery,
    } from 'services/shop';
    import { push } from 'svelte-spa-router';

    import SHOP_CONFIG from 'configs/shop';

    import Nav from 'components/Nav';
    import Page from 'components/Page';
    import Header from 'components/Header';
    import ProductGrid from 'components/ProductGrid';
    import ProductFilters from 'components/ProductGrid/ProductFilters';
    import ShoppingCartCalculator from 'components/ShoppingCartCalculator';
    import FeaturedProducts from 'components/FeaturedProducts';
    import Lottie from 'components/Lottie';

    export let params = {};

    let observer;
    let products = [];
    let featuredProducts = [];
    let lastDoc; // The last Firebase 'doc' in the collection
    let resultsType; // The type of results we are displaying
    let canSeeFeaturedProducts;
    let canSeeFilters = true;

    let filters;
    let searchQuery;

    let isLoadingProducts = true;
    let isLoadingMoreProducts = false;
    let isLoadingFeaturedProducts = true;

    let retailer;

    let isObserving = false;
    let sentinel;

    $: {
        canSeeFeaturedProducts =
            !isLoadingFeaturedProducts &&
            featuredProducts.length > 0 &&
            !params.retailerId &&
            (resultsType == SHOP_CONFIG.products.pagination.type.default ||
                (filters && !filters.retailer && !filters.category & !filters.sort));

        if (retailer && params.retailerId != retailer.id) {
            loadRetailer(params.retailerId);
        }

        canSeeFilters = !params.retailerId;
    }

    onMount(() => {
        resultsType = SHOP_CONFIG.products.pagination.type.default;

        // Set up Observer
        initObserver();
    });

    let unsubscribe = user.subscribe((user) => {
        if (user.country) {
            shop.fetch(user.country.id).then(() => {
                getFeaturedProducts(user.country.id).then((data) => {
                    isLoadingFeaturedProducts = false;
                    featuredProducts = data.results;
                });

                // If we're on the retailer page load the Retailer
                if (params.retailerId) {
                    loadRetailer(params.retailerId); // Loads Products for a specified Retailer
                } else {
                    loadProducts();
                }
            });
        }
    });

    onDestroy(() => {
        unsubscribe();
        unobserve();
    });

    function initObserver() {
        sentinel = document.querySelector('#sentinel');
        observer = new IntersectionObserver(
            (entries) => {
                if (entries.some((entry) => entry.intersectionRatio > 0) && isObserving && !isLoadingMoreProducts) {
                    loadMoreProducts();
                } else {
                    isObserving = true; // Prevents callback from being called the first time 'observe' is triggered
                }
            },
            {
                threshold: 0.5,
            },
        );
    }

    function observe() {
        observer.observe(sentinel);
    }

    function unobserve() {
        if (observer) {
            isObserving = false;
            observer.unobserve(document.querySelector('#sentinel'));
        }
    }

    function displayProducts(promise) {
        products = [];
        isLoadingProducts = true;

        promise
            .then((data) => {
                products = data.results;
                lastDoc = data.lastDoc;

                if (data.results.length >= SHOP_CONFIG.products.pagination.limit) {
                    observe();
                }
            })
            .finally(() => {
                setTimeout(() => {
                    isLoadingProducts = false;
                }, 500);
            });
    }

    function resetPagination() {
        unobserve();
        lastDoc = null;
    }

    function loadMoreProducts() {
        let promise;
        isLoadingMoreProducts = true;

        switch (resultsType) {
            case SHOP_CONFIG.products.pagination.type.default:
                promise = getProductsForRetailers($shop.retailers, lastDoc);
                break;
            case SHOP_CONFIG.products.pagination.type.filter:
                promise = getFilteredProductsForRetailers(filters, $shop.retailers, lastDoc);
                break;
            case SHOP_CONFIG.products.pagination.type.search:
                promise = getProductsBySearchQuery(searchQuery, $user.country.id, lastDoc);
                break;
        }

        promise
            .then((data) => {
                products = products.concat(data.results);
                lastDoc = data.lastDoc;

                if (data.results.length < SHOP_CONFIG.products.pagination.limit) {
                    unobserve();
                }
            })
            .finally(() => {
                isLoadingMoreProducts = false;
            });
    }

    function loadProducts(retailers = null) {
        if (!retailers) {
            retailers = $shop.retailers;
        }

        resetPagination();
        resultsType = SHOP_CONFIG.products.pagination.type.default;
        displayProducts(getProductsForRetailers(retailers));
    }

    function loadRetailer(retailerId) {
        retailer = $shop.retailers.find((retailer) => {
            return retailer.id == retailerId || retailer.displayName.replace(`-${$user.country.id}`) == retailerId;
        });

        if (retailer) {
            loadProducts([retailer]);
        } else {
            console.error('Retailer with ID ' + params.retailerId + ' does not exist! Redirecting to homepage ...');
            push('/');
        }
    }

    function handleFilterSelect(event) {
        resetPagination();
        resultsType = SHOP_CONFIG.products.pagination.type.filter;
        filters = event.detail;
        displayProducts(getFilteredProductsForRetailers(filters, $shop.retailers));
    }

    function handleSearch(event) {
        resetPagination();
        searchQuery = event.detail.searchQuery;

        if (searchQuery != null) {
            resultsType = SHOP_CONFIG.products.pagination.type.search;
            displayProducts(getProductsBySearchQuery(searchQuery, $user.country.id));
        } else {
            loadProducts();
        }
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
#sentinel {
  position: absolute;
  z-index: -1;
  bottom: 5%;
  right: 0;
  opacity: 0;
  width: 1px;
  height: 1px;
}

.root {
  height: 100%;
}

.indicator {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-top: 18.75em;
}
.indicator__icon {
  width: 120px;
  height: auto;
}
.indicator__title {
  font-size: 1.5em;
  color: #999999;
}
.indicator__title--loader {
  margin-top: -0.3125em;
  margin-bottom: 0.625em;
}
.indicator__title--no-results {
  margin-top: -0.3125em;
  margin-bottom: 0.5em;
}
.indicator__subtitle {
  font-size: 0.875em;
  color: rgba(153, 153, 153, 0.8);
}

.loading {
  margin-top: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading__icon {
  width: 120px;
  height: auto;
}</style>

<div id="root" class="root">
    <Nav on:search={handleSearch} />
    <Page>
        <div slot="header">
            <Header>
                <span slot="title">
                    {#if retailer}{retailer.displayName}{:else}Shop{/if}
                </span>
            </Header>
        </div>
        <div slot="content">
            {#if canSeeFilters}
                <ProductFilters on:filterSelect={handleFilterSelect} />
            {/if}
            {#if canSeeFeaturedProducts}
                <FeaturedProducts isLoading={isLoadingFeaturedProducts} products={featuredProducts} />
            {/if}
            {#if !isLoadingProducts && products.length}
                <ProductGrid {products} />
            {:else if isLoadingProducts}
                <div class="indicator animate__animated animate__fadeIn">
                    <div class="indicator__icon">
                        <Lottie jsonPath="lottie/products-loading-2.json" />
                    </div>
                    <div class="indicator__title indicator__title--loader">Searching for Products</div>
                    <div class="indicator__subtitle">Hang tight, we'll be with you shortly ...</div>
                </div>
            {:else}
                <div class="indicator animate__animated animate__fadeIn">
                    <div class="indicator__icon">
                        <Lottie jsonPath="lottie/products-empty.json" loop={false} />
                    </div>
                    <div class="indicator__title indicator__title--no-results">Oops! We found no results.</div>
                    <div class="indicator__subtitle">Try modifying your search or filters and trying again.</div>
                </div>
            {/if}
            {#if isLoadingMoreProducts}
                <div class="loading animate__animated animate__fadeIn">
                    <div class="loading__icon">
                        <Lottie jsonPath="lottie/products-loading-2.json" />
                    </div>
                </div>
            {/if}
            <div id="sentinel" />
        </div>
    </Page>
    <ShoppingCartCalculator />
</div>
