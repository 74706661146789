<script>
    import { fade } from 'svelte/transition';

    import ProductListItem from './ProductListItem';

    export let products = [];
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.list__item {
  width: 100%;
}</style>

<ul class="list">
    {#each products as product, i}
        <li class="list__item" in:fade={{ duration: 250, delay: i * 100 }}>
            <ProductListItem {product} />
        </li>
    {/each}
</ul>
