<script>

</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.layout {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 2.5em 0;
}
@media (min-width: 1200px) {
  .layout {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.layout__section {
  background: #fff;
  margin-bottom: 1.5625em;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}
@media (min-width: 1200px) {
  .layout__section {
    margin: 0;
  }
}
.layout__section--left {
  width: 100%;
}
@media (min-width: 1200px) {
  .layout__section--left {
    width: 64%;
    min-height: 75vh;
  }
}
.layout__section--right {
  width: 100%;
}
@media (min-width: 1200px) {
  .layout__section--right {
    width: 34%;
  }
}</style>

<div class="layout">
    <div class="layout__section layout__section--left animate__animated animate__fadeInLeft">
        <slot name="left" />
    </div>
    <div class="layout__section layout__section--right">
        <slot name="right" />
    </div>
</div>
