<script>
    import shoppingCart from 'stores/shoppingCart';
    import Lottie from 'components/Lottie';
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.shopping-cart {
  position: relative;
  cursor: pointer;
}
.shopping-cart__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  background: #8672ae;
  border-radius: 50%;
  border: 2px solid #5123ad;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}
.shopping-cart__icon {
  width: 40px;
}</style>

<div class="shopping-cart">
    {#if $shoppingCart.products.length}
        <div class="shopping-cart__badge animate__animated animate__zoomIn">{$shoppingCart.numProducts}</div>
    {/if}
    <div class="shopping-cart__icon">
        <Lottie jsonPath="/lottie/cart-icon.json" loop={false} />
    </div>
    <!-- <img src="/images/cart_icon@2x.svg" height="35" alt="cart" /> -->
</div>
