<script>
    import { pop } from 'svelte-spa-router';
    import Container from 'components/Container';
    import Lottie from 'components/Lottie';
    import AnimatedGrid from 'components/AnimatedGrid';

    export let canSeeBack = false;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.header {
  position: relative;
  overflow: hidden;
  padding: 3.125em 0;
  background: #f4f4f4;
  background: url("/images/header_bg_mobile@2x.jpg") no-repeat top right;
  background-size: cover;
}
@media (min-width: 600px) {
  .header {
    background: url("/images/header_bg@2x.jpg") no-repeat top right;
    background-size: cover;
  }
}
@media (min-width: 1200px) {
  .header {
    height: 400px;
  }
}
.header__title {
  position: relative;
  z-index: 2;
  font-size: 3.5em;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 1;
}
@media (min-width: 600px) {
  .header__title {
    font-size: 4.5em;
  }
}
.header__subtitle {
  position: relative;
  z-index: 2;
  margin-top: 0.3125em;
  font-size: 1.125em;
  color: #999999;
  font-weight: 400;
  text-transform: capitalize;
}
@media (min-width: 600px) {
  .header__subtitle {
    font-size: 1.5em;
  }
}
.header__grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 3;
  margin-top: 1.875em;
}
.header-back__label {
  display: inline-block;
  margin-left: 0.625em;
  font-size: 0.875em;
  color: #999999;
  font-weight: 400;
  transition: 0.25s all ease-in;
}
.header-back__label:hover {
  color: #5123ad;
}
.header-back__icon {
  display: inline-block;
}</style>

<header class="header">
    <div class="header__grid">
        <AnimatedGrid />
    </div>
    <Container>
        <h1 class="header__title">
            <slot name="title" />
        </h1>
        <h2 class="header__subtitle">
            <slot name="subtitle" />
        </h2>
        {#if canSeeBack}
            <div
                class="header-back"
                on:click={() => {
                    pop();
                }}>
                <img src="images/back_arrow_icon@2x.svg" class="header-back__icon" height="25" alt="back" />
                <div class="header-back__label">
                    <slot name="back" />
                </div>
            </div>
        {/if}
    </Container>
</header>
