<script>
    import { onMount } from 'svelte';

    import user from 'stores/user';
    import shop from 'stores/shop';
    import shoppingCart from 'stores/shoppingCart';
    import { getRetailerLogoUrl } from 'services/shop';

    import { formatCurrency } from 'services/util';

    import Button from 'components/Button';
    import Lottie from 'components/Lottie';

    export let product;

    let canSeeMoreInfo = false;
    let lastProductAddedId = null;

    function handleClick(product) {
        shoppingCart.add(product);

        lastProductAddedId = product.id;
        setTimeout(() => {
            lastProductAddedId = null;
        }, 1500);
    }

    function handleMoreInfoShow() {
        canSeeMoreInfo = true;
    }

    function handleMoreInfoHide() {
        canSeeMoreInfo = false;
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.product {
  height: 300px;
  background: #f4f4f4;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 10px;
}
.product:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
}
.product--out-of-stock {
  display: none;
  opacity: 1;
}
@media (min-width: 600px) {
  .product {
    height: 420px;
  }
}

.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 10px;
  cursor: pointer;
}
@media (min-width: 600px) {
  .thumbnail {
    height: 50%;
  }
}
.thumbnail--out-of-stock {
  cursor: not-allowed;
}
.thumbnail--out-of-stock .thumbnail__img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  opacity: 0.5;
}
.thumbnail--out-of-stock .thumbnail__overlay {
  display: none !important;
}
.thumbnail__img {
  width: auto;
  height: 100%;
}
.thumbnail:hover .thumbnail__overlay {
  display: flex;
  background: rgba(81, 35, 173, 0.8);
}
.thumbnail__overlay {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
}
.thumbnail__overlay--out-of-stock {
  display: none !important;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
}
.checkmark__icon {
  width: 100px;
  height: 100px;
}
@media (min-width: 600px) {
  .checkmark__icon {
    width: 200px;
    height: 200px;
  }
}

.details {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 45%;
  padding: 0.3125em 1.25em;
}
@media (min-width: 600px) {
  .details {
    height: 40%;
  }
}
.details__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.details__group--summary {
  height: 65%;
}
.details__group--price {
  justify-content: flex-start;
  height: 25%;
}

.details-summary {
  width: 70%;
}
.details-summary__title {
  margin-bottom: 0.3125em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1;
}
.details-summary__desc {
  font-size: 0.875em;
  color: #999999;
}
.details-summary__price {
  font-size: 1em;
}
@media (min-width: 600px) {
  .details-summary__price {
    font-size: 1.25em;
    font-weight: 400;
  }
}
.details-summary__price--strike {
  margin-right: 0.625em;
  text-decoration: line-through;
  font-size: 0.875em;
  opacity: 0.5;
}
.details-summary__price--sale {
  color: #22773b;
}

.info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5625em;
  background: #f4f4f4;
  -webkit-animation-duration: 250ms;
          animation-duration: 250ms;
}
.info__icon {
  width: 40px;
  height: 40px;
}

.info-details {
  width: 80%;
}
.info-details__title {
  margin-bottom: 0.3125em;
  font-size: 0.875em;
  font-weight: 700;
}
.info-details__price {
  margin-bottom: 0.625em;
  font-size: 0.875em;
  font-weight: 300;
}
.info-details__desc {
  font-size: 0.875em;
  color: #999999;
}

.actions {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 15%;
  padding: 0.25em;
  border-radius: 0 0 10px 10px;
}
@media (min-width: 600px) {
  .actions {
    height: 10%;
    flex-flow: row nowrap;
  }
}
.actions__btn-add {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: #5123ad;
  color: #fff;
  display: inline-block;
  height: 100%;
  width: 100%;
  margin: 0 0.3125em;
  padding: 0;
  font-size: 0.625em;
}
.actions__btn-add:hover {
  background: #471f98;
  box-shadow: 0px 0px 5px 6px rgba(81, 35, 173, 0.05);
}
.actions__btn-add:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
@media (min-width: 600px) {
  .actions__btn-add {
    width: 100%;
    height: 100%;
  }
}
.actions__btn-add--out-of-stock {
  cursor: not-allowed;
  background: #662ed5;
}
.actions__btn-info {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  color: #5123ad;
  border: 1px solid #5123ad;
  cursor: default;
  display: none;
}
.actions__btn-info:hover {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05);
}
.actions__btn-info:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.actions__btn-info:hover {
  box-shadow: none;
  background: #5123ad;
  color: #fff;
}
@media (min-width: 600px) {
  .actions__btn-info {
    display: inline-block;
    height: 50%;
    width: 100%;
    margin: 0 0.3125em;
    padding: 0;
    font-size: 0.625em;
    width: 100%;
    height: 100%;
  }
}</style>

<div class="product" class:product--out-of-stock={!product.inStock}>
    <div class="thumbnail" class:thumbnail--out-of-stock={!product.inStock}>
        <img
            src={product.imageUrl ? product.imageUrl : '/images/default_product@2x.jpg'}
            class="thumbnail__img"
            alt="thumbnail" />
        {#if product.id === lastProductAddedId}
            <div class="checkmark animate__animated animate__zoomIn">
                <div class="checkmark__icon">
                    <Lottie jsonPath="lottie/checkmark.json" loop={false} />
                </div>
            </div>
        {/if}
        <div
            class="thumbnail__overlay animate__animated animate__fadeIn"
            on:click={() => {
                handleClick(product);
            }}>
            <div class="animate__animated animate__pulse">
                <Button iconUrl="images/cart_icon_white@2x.svg" transparent>Add to Cart</Button>
            </div>
        </div>
    </div>
    <div class="details">
        {#if canSeeMoreInfo}
            <div class="info animate__animated animate__fadeIn">
                <div class="info__icon">
                    <Lottie jsonPath="lottie/info.json" loop={false} />
                </div>
                <div class="info-details">
                    <div class="info-details__desc">
                        {@html product.desc}
                    </div>
                </div>
            </div>
        {/if}

        <div class="details__group details__group--summary">
            <div class="details-summary">
                <div class="details-summary__title">{product.name}</div>
                <div class="details-summary__desc">{product.specs}</div>
            </div>
            <div class="details-retailer">
                <img src={getRetailerLogoUrl(product.retailerId)} height="47" alt="retailer" />
            </div>
        </div>
        <div class="details__group details__group--price">
            <div class="details-summary__price" class:details-summary__price--strike={product.salePrice}>
                {formatCurrency(product.price, $user.country.id, $user.country.currencyCode)}
            </div>
            {#if product.salePrice}
                <div class="details-summary__price details-summary__price--sale">
                    {formatCurrency(product.salePrice, $user.country.id, $user.country.currencyCode)}
                </div>
            {/if}
        </div>
    </div>
    <div class="actions">
        <button
            class="actions__btn-add"
            disabled={!product.inStock}
            on:click={() => {
                handleClick(product);
            }}>
            {product.inStock ? 'Add to Cart' : 'Out of Stock'}
        </button>
        <button class="actions__btn-info" on:mouseenter={handleMoreInfoShow} on:mouseleave={handleMoreInfoHide}>
            More Info
        </button>
    </div>
</div>
