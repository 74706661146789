<script>
    import { createEventDispatcher } from 'svelte';

    import Lottie from 'components/Lottie';

    const dispatch = createEventDispatcher();

    let searchQuery;

    function handleSubmit(e) {
        e.preventDefault();
        dispatch('search', {
            searchQuery,
        });
    }

    function handleClear() {
        searchQuery = null;
        dispatch('search', {
            searchQuery,
        });
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.search-bar {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search-bar__input {
  position: relative;
  width: 175px;
  padding: 10px 15px;
  background: #f4f4f4;
  border: none;
  border-radius: 15px;
  outline: 0;
  font-size: 0.875em;
  text-align: left;
}
@media (min-width: 600px) {
  .search-bar__input {
    width: 340px;
  }
}
.search-bar__btn {
  cursor: pointer;
  position: absolute;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: #5123ad;
  border: none;
  border-radius: 50%;
  outline: 0;
  transition: 0.25s all ease-in;
}
.search-bar__btn:hover {
  background: #3d1a83;
}
.search-bar__btn--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.search-bar__clear {
  cursor: pointer;
  position: absolute;
  right: 40px;
  width: 25px;
  height: 25px;
  font-size: 0.75em;
  font-weight: 700;
  color: #999999;
  letter-spacing: 0.03125em;
  text-transform: uppercase;
}</style>

<form on:submit={handleSubmit} class="search-bar">
    <input type="text" bind:value={searchQuery} class="search-bar__input" placeholder="Search by Product Name" />
    {#if searchQuery}
        <div class="search-bar__clear" on:click={handleClear}>
            <Lottie jsonPath="lottie/search-clear.json" loop={false} />
        </div>
    {/if}
    <button
        class="search-bar__btn"
        class:search-bar__btn--disabled={!searchQuery || searchQuery.length === 0}
        type="submit">
        <img src="images/search_icon@2x.svg" height="20" alt="search" />
    </button>
</form>
