<script>
    import { onMount } from 'svelte';

    import Router, { location } from 'svelte-spa-router';
    import Modal from 'svelte-simple-modal';

    export let routes;

    $: {
        // Scroll to the top of the page whenever the
        // '$location' cha    import { debug } from 'svelte/internal';
        if ($location) window.scrollTo(0, 0);
    }

    onMount(() => {
        if ('<@ENV@>' == 'PROD') {
            try {
                const appCheck = firebase.appCheck();
                appCheck.activate('6Le5t30qAAAAAEngPWOdAMlhX37VCOl-UvKY6tgE', true);
                console.debug('AppCheck initialized: Production');
            } catch (e) {
                console.error(e);
            }

            firebase.analytics();
        } else if ('<@ENV@>' == 'STAGING') {
            try {
                const appCheck = firebase.appCheck();
                appCheck.activate('6LfzBXwqAAAAAJ90vS9rJWHtPlP6cXGE3Dafr059', true);
                console.debug('AppCheck initialized: Staging');
            } catch (e) {
                console.error(e);
            }
        } else {
            // Enable debug mode for development (remove in production)
            self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
            console.debug('AppCheck initialized: Debug Mode');
        }
    });

    function handleConditionsFailed(event) {
        event.detail.userData.onConditionsFailed();
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
:global(body) {
  background: #e9eae9;
  font-size: 0.8125em;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 600px) {
  :global(body) {
    font-size: 0.875em;
  }
}
@media (min-width: 900px) {
  :global(body) {
    font-size: 1em;
  }
}

:global(a) {
  text-decoration: none;
  color: #5123ad;
}
:global(a):hover {
  color: #3d1a83;
}

:global(p) {
  line-height: 1.25;
}

:global(strong) {
  font-weight: 700;
}

:global(table) {
  width: 100%;
  border: solid 1px #f5f4ff;
  border-collapse: collapse;
  border-spacing: 0;
}

:global(th) {
  padding: 1.25em;
  text-align: left;
  border-bottom: 1px solid #f5f4ff;
  border-right: 1px solid #f5f4ff;
  font-size: 0.875em;
}

:global(td) {
  padding: 0.9375em;
  border: solid 1px #f5f4ff;
  font-size: 0.8125em;
  color: #000;
}</style>

<main>
    <Modal>
        <Router {routes} on:conditionsFailed={handleConditionsFailed} />
    </Modal>
</main>
