<script>

</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.container {
  margin: 0 auto;
  padding: 0 1.25em;
}
@media (min-width: 600px) {
  .container {
    margin: 0 auto;
    padding: 0 1.875em;
  }
}
@media (min-width: 1200px) {
  .container {
    margin: 0 auto;
    padding: 0 2.5em;
  }
}</style>

<div class="container">
    <slot />
</div>
