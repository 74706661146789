<script>
    import { onMount, getContext } from 'svelte';
    import Select from 'svelte-select';

    import adminService from 'services/admin';
    import utilService from 'services/util';

    import user from 'stores/user';

    import Page from 'components/Page';
    import Header from 'components/Header';
    import Nav from 'components/Nav';
    import Button from 'components/Button';
    import FullLayout from 'components/FullLayout';
    import { EditProductModal, editProductModalOptions } from 'components/EditProductModal';
    import { ConfirmModal } from 'components/ConfirmModal';

    import Lottie from 'components/Lottie';

    const { open } = getContext('simple-modal');

    let products = [];
    let retailers = [];
    let countries = [];
    let selectedRetailer = null;
    let selectedCountry = null;
    let isLoading = false;

    onMount(() => {
        isLoading = true;
        loadCountries();
    });

    function loadRetailers(countryId) {
        products = [];

        return adminService.getRetailers(countryId, $user.profile.retailers).then((data) => {
            retailers = data.map((retailer) => {
                return { label: retailer.displayName, value: retailer.id };
            });

            selectedRetailer = retailers[0];

            if (retailers.length == 0) {
                isLoading = false;
            }
        });
    }

    function loadCountries() {
        return adminService.getCountries($user.profile.countries).then((data) => {
            countries = data.map((country) => {
                return {
                    label: country.displayName,
                    value: country.id,
                };
            });

            selectedCountry = countries[0];
        });
    }

    function loadProducts() {
        isLoading = true;

        adminService
            .getProductsByRetailer(selectedRetailer.value)
            .then(
                (data) => {
                    products = data;
                },
                (err) => {
                    console.log(err);
                },
            )
            .finally(() => {
                isLoading = false;
            });
    }

    function handleEditProduct(product) {
        open(
            EditProductModal,
            {
                product,
                retailers,
                selectedCountry,
                onSaveProduct: (updatedProduct) => {
                    if (!product.id || product.retailerId != selectedRetailer.value) {
                        // If modifying Retailer or adding new Product, refresh the list
                        loadProducts();
                    } else {
                        product = updatedProduct;
                        products = products;
                    }
                },
            },
            editProductModalOptions,
        );
    }

    function handleRemoveProduct(product) {
        open(ConfirmModal, {
            title: `Remove the ${product.name} from the Shop?`,
            message: `By confirming you will permanently remove the ${product.name} from the Shop. Are you sure?`,
            onConfirm: () => {
                adminService.removeProduct(product.id).then(() => {
                    product.isHidden = true;
                    products = products;
                });
            },
        });
    }

    function getKeywords(product) {
        let keywords =
            product.metadata && product.metadata.keywords && product.metadata.keywords.length
                ? product.metadata.keywords.join(', ')
                : 'None';

        return keywords;
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.root {
  padding: 1.5625em;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5625em;
  border-bottom: 1px solid #f5f4ff;
}
.filters__left {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.filters__right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter {
  width: 100%;
  margin: 0 1.25em;
}
.filter--actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filter__label {
  margin-bottom: 0.625em;
  display: block;
  font-size: 1em;
  font-weight: 700;
}
.filter__select {
  width: 100%;
  font-size: 0.875em;
  --borderRadius: 10px;
  --itemHoverBG: #f4f4f4;
  --itemIsActiveBG: #822c7d;
  --multiItemActiveBG: #166f86;
  --multiClearBG: #999999;
}

.products {
  margin-top: 1.5625em;
}

.products-table__hidden {
  display: none;
}
.products-table__tr--out-of-stock {
  opacity: 0.5;
  background: #eee;
}
.products-table th,
.products-table td {
  max-width: 150px;
  line-height: 1.25;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.actions__btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.125em;
  padding: 0;
  outline: 0;
  border: none;
  background: transparent;
  opacity: 0.8;
  transition: all 0.25s ease-in;
}
.actions__btn:hover {
  opacity: 1;
}

.in-stock,
.is-featured {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 1.5625em;
  font-size: 1.5em;
  color: #999999;
}
.message__loader {
  width: 100px;
  height: 100px;
}

.product__code {
  font-size: 1.125em;
  letter-spacing: 1px;
  color: #5123ad;
}</style>

<Nav />
<Page>
    <div slot="header">
        <Header>
            <span slot="title">Admin</span>
            <span slot="subtitle">Products</span>
        </Header>
    </div>
    <div slot="content">
        <FullLayout>
            <div class="root">
                <div>
                    <div class="filters">
                        <div class="filters__left">
                            <div class="filter">
                                <div class="filter__label">Country</div>
                                <div class="filter__select">
                                    <Select
                                        items={countries}
                                        placeholder="Select Country"
                                        on:select={() => {
                                            loadRetailers(selectedCountry.value);
                                        }}
                                        bind:selectedValue={selectedCountry}
                                        showIndicator={true}
                                        isClearable={false}
                                        showChevron={true} />
                                </div>
                            </div>
                            <div class="filter">
                                <div class="filter__label">Retailer</div>
                                <div class="filter__select">
                                    <Select
                                        items={retailers}
                                        placeholder="Select Retailer"
                                        on:select={loadProducts}
                                        bind:selectedValue={selectedRetailer}
                                        showIndicator={true}
                                        isClearable={false}
                                        showChevron={true} />
                                </div>
                            </div>
                        </div>
                        <div class="filters__right">
                            <div class="filter filter--actions">
                                <Button
                                    primary
                                    onClick={() => {
                                        handleEditProduct({});
                                    }}>
                                    Add New Product
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="products">
                    <table class="products-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>In Stock</th>
                                <th>Is Featured</th>
                                <th>Code</th>
                                <th>Specs</th>
                                <th>Keywords</th>
                                <th>Prices</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {#if selectedRetailer && products.length == 0 && !isLoading}
                                <tr class="animate__animated animate__fadeIn">
                                    <td colspan="8">
                                        <div class="message">
                                            <div>There are currently no Products for {selectedRetailer.label}</div>
                                        </div>
                                    </td>
                                </tr>
                            {/if}
                            {#if selectedCountry && retailers.length == 0 && !isLoading}
                                <tr class="animate__animated animate__fadeIn">
                                    <td colspan="8">
                                        <div class="message">
                                            <div>There are currently no Retailers for {selectedCountry.label}</div>
                                        </div>
                                    </td>
                                </tr>
                            {/if}
                            {#if isLoading}
                                <tr class="animate__animated animate__fadeIn">
                                    <td colspan="8">
                                        <div class="message">
                                            <div class="message__loader">
                                                <Lottie jsonPath="lottie/products-loading-2.json" />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            {/if}

                            {#each products as product, i}
                                <tr
                                    class="products-table__tr"
                                    class:products-table__tr--out-of-stock={!product.inStock}
                                    class:products-table__hidden={product.isHidden}>
                                    <td>{product.name}</td>
                                    <td>
                                        <div class="in-stock">
                                            {#if product.inStock}
                                                <img src="images/checkmark.svg" height="20" alt="yes" />
                                            {:else}
                                                <img src="images/x.svg" height="15" alt="no" />
                                            {/if}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="is-featured">
                                            {#if product.isFeatured}
                                                <img src="images/checkmark.svg" height="20" alt="yes" />
                                            {:else}
                                                <img src="images/x.svg" height="15" alt="no" />
                                            {/if}
                                        </div>
                                    </td>
                                    <td>
                                        <span class="product__code">{product.code}</span>
                                    </td>
                                    <td>{product.specs}</td>
                                    <td>{getKeywords(product)}</td>
                                    <td>
                                        <div>
                                            <strong>Price</strong>
                                            : {utilService.formatCurrency(product.price, 'tt')}
                                        </div>
                                        <div>
                                            <strong>Sale Price</strong>
                                            : {product.salePrice ? utilService.formatCurrency(product.salePrice, 'tt') : 'N/A'}
                                        </div>
                                        <div>
                                            <strong>Retailer Price</strong>
                                            : {utilService.formatCurrency(product.retailerPrice, 'tt')}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="actions">
                                            <button
                                                class="actions__btn"
                                                on:click={() => {
                                                    handleEditProduct(product);
                                                }}>
                                                <img
                                                    src="images/admin_products_edit_icon@2x.svg"
                                                    height="40"
                                                    alt="edit" />
                                            </button>
                                            <button
                                                class="actions__btn"
                                                on:click={() => {
                                                    handleRemoveProduct(product);
                                                }}>
                                                <img
                                                    src="images/admin_products_delete_icon@2x.svg"
                                                    height="40"
                                                    alt="edit" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                        </tbody>
                    </table>
                </div>
            </div>
        </FullLayout>
    </div>
</Page>
