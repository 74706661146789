<script>
    import { getContext } from 'svelte';
    import { link, push, location } from 'svelte-spa-router';
    import active from 'svelte-spa-router/active';

    import shoppingCart from 'stores/shoppingCart';
    import user from 'stores/user';
    import authService from 'services/auth';

    import USERS_CONFIG from 'configs/users';

    import Container from 'components/Container';
    import CountrySelector from 'components/CountrySelector';
    import ShoppingCartIcon from 'components/ShoppingCartIcon';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';
    import SearchBar from './SearchBar';

    const { open } = getContext('simple-modal');

    function handleClickShoppingCart() {
        if ($shoppingCart.numProducts > 0) {
            push('/cart');
        } else {
            open(
                AlertModal,
                {
                    title: 'Your cart is empty!',
                    message: 'Please add at least one item to your cart before continuing to the Review Cart page.',
                },
                alertModalOptions,
            );
        }
    }

    function isAdmin() {
        return new RegExp(/admin/).test($location);
    }

    function handleLogout() {
        authService.logout().then(
            () => {
                push('/admin');
            },
            (err) => {
                open(
                    AlertModal,
                    {
                        title: 'Uh oh! Unable to log you out.',
                        message: err.message,
                    },
                    alertModalOptions,
                );
            },
        );
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 4.6875em;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 2px;
}
@media (min-width: 600px) {
  .nav {
    height: 4.375em;
    padding: 0.625em 0;
  }
}
.nav__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.nav__list {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  list-style: none;
  list-style-type: none;
}

.search {
  margin-right: 0.5em;
}

.icons {
  display: flex;
}
.icons__cart, .icons__country {
  margin-left: 0.25em;
}

.links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0;
}
.links__link {
  cursor: pointer;
  margin-left: 4.6875em;
  font-size: 1em;
  color: #000;
}
.links__link:hover {
  color: #5123ad;
}

:global(a.active) {
  color: #5123ad !important;
  font-weight: 700;
}</style>

<nav class="nav">
    <Container>
        <div class="nav__flex">
            <ul class="nav__list">
                <li class="nav__logo">
                    <a use:link href="/">
                        <img src="/images/logo@2x.svg" height="50" alt="logo" />
                    </a>
                </li>
            </ul>
            <ul class="nav__list">
                {#if isAdmin()}
                    <li class="links">
                        {#if $user.profile.role != USERS_CONFIG.roles.retailerAdmin}
                            <a href="/admin/orders/pending" use:link use:active={'/admin/orders/*'} class="links__link">
                                Orders
                            </a>
                            <a href="/admin/retailers" use:link use:active={'/admin/retailers'} class="links__link">
                                Retailers
                            </a>
                        {/if}
                        <a href="/admin/products" use:link use:active={'/admin/products'} class="links__link">
                            Products
                        </a>
                        <div class="links__link" on:click={handleLogout}>Log out</div>
                    </li>
                {:else}
                    <li class="search">
                        <SearchBar on:search />
                    </li>
                    <li class="icons">
                        <div class="icons__cart" on:click={handleClickShoppingCart}>
                            <ShoppingCartIcon />
                        </div>
                        <div class="icons__country">
                            <CountrySelector />
                        </div>
                    </li>
                {/if}
            </ul>

        </div>
    </Container>
</nav>
