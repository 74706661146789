<script>
    import { onMount, onDestroy } from 'svelte';
    import anime from 'animejs/lib/anime.es.js';

    export let opacity = 0.05;

    let w = 0;
    let h = 0;
    let numColumns;
    let numRows;
    let numItems;
    let itemWidth = 25;
    let itemHeight = 25;
    let animation;

    function random(min, max) {
        return min + Math.random() * (max - min);
    }

    onMount(() => {
        numColumns = Math.round(w / itemWidth);
        numRows = Math.round(h / itemHeight);
        numItems = Math.round(numRows * numColumns);

        // Need to wrap this in a setTimeout to ensure the grid items
        // are rendered to the DOM prior to starting the animation
        setTimeout(() => {
            animation = anime({
                targets: '.grid__item',
                scale: [
                    { value: 0.1, easing: 'easeOutSine', duration: 500 },
                    { value: 1, easing: 'easeInOutQuad', duration: 1000 },
                    { value: 1, easing: 'easeInOutQuad', duration: 1000, delay: 2500 },
                ],
                loop: true,
                autoplay: false,
                delay: anime.stagger(250, { grid: [numColumns, numRows], from: 'center' }),
            });

            animation.play();
        }, 100);
    });

    onDestroy(() => {
        numItems = 0;
        animation.pause();
    });

    // function handleWindowResize() {}
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.grid {
  overflow: hidden;
  visibility: hidden;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1px;
}
.grid--visible {
  visibility: visible;
}
.grid__item {
  background-color: #000;
}</style>

<!-- <svelte:window on:resize={handleWindowResize} /> -->

<div
    class="grid"
    class:grid--visible={numItems > 0}
    style={`grid-template-columns: repeat(auto-fill, minmax(${itemWidth}px, 1fr));`}
    bind:clientWidth={w}
    bind:clientHeight={h}>
    {#each Array(numItems) as _, i}
        <div class="grid__item" style={`width: ${itemWidth}px; height: ${itemHeight}px; opacity: ${opacity}`} />
    {/each}
</div>
