<script>
    import { fly } from 'svelte/transition';

    import ProductGridItem from 'components/ProductGrid/ProductGridItem';
    import Lottie from 'components/Lottie';

    export let products = [];
    export let isLoading = true;

    let productsElement;

    function scroll(dir) {
        productsElement.scrollTo({
            top: productsElement.scrollTop,
            left: dir == 'right' ? productsElement.scrollLeft + 300 : productsElement.scrollLeft - 300,
            behavior: 'smooth',
        });
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.fp {
  position: relative;
  width: 100%;
  min-height: 300px;
  margin-bottom: 1.5625em;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
}
.fp__title {
  align-self: flex-start;
  display: inline-block;
  padding: 0.625em 1.25em;
  background: #5123ad;
  border-left: 5px solid #471f98;
  border-top: 2px solid #5b27c2;
  font-size: 0.875em;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.125em;
}
.fp__arrow {
  position: absolute;
  z-index: 200;
  top: 40%;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
@media (min-width: 600px) {
  .fp__arrow {
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 900px) {
  .fp__arrow {
    width: 75px;
    height: 75px;
  }
}
.fp__arrow--right {
  right: -10px;
}
@media (min-width: 600px) {
  .fp__arrow--right {
    right: -30px;
  }
}
.fp__arrow--left {
  left: -10px;
}
@media (min-width: 600px) {
  .fp__arrow--left {
    left: -30px;
  }
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 300px;
}
.indicator__loader {
  width: 100px;
  height: 100px;
}

.products {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}
.products::-webkit-scrollbar {
  display: none;
}
.products__product {
  flex: 0 0 auto;
  max-width: 49%;
  margin: 0 0.1875em;
}
@media (min-width: 600px) {
  .products__product {
    max-width: 250px;
  }
}
@media (min-width: 900px) {
  .products__product {
    max-width: 300px;
  }
}</style>

<div class="fp">
    <div class="fp__title">Featured Products</div>
    {#if products.length > 0 && !isLoading}
        <div class="fp__arrow fp__arrow--right" on:click={() => scroll('right')} on:mouseenter={() => scroll('right')}>
            <Lottie jsonPath="lottie/fp-arrow-right.json" />
        </div>
        <div class="fp__arrow fp__arrow--left" on:click={() => scroll('left')} on:mouseenter={() => scroll('left')}>
            <Lottie jsonPath="lottie/fp-arrow-left.json" />
        </div>
        <div bind:this={productsElement} class="products">
            {#each products as product, i}
                <div class="products__product">
                    <div in:fly={{ x: -100, duration: 500, delay: i * 150 }}>
                        <ProductGridItem {product} />
                    </div>
                </div>
            {/each}
        </div>
    {:else if isLoading}
        <div class="indicator">
            <div class="indicator__loader">
                <Lottie jsonPath="lottie/products-loading-2.json" />
            </div>
        </div>
    {/if}
</div>
