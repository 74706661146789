<script>
    import { onMount, getContext } from 'svelte';
    import Select from 'svelte-select';
    import { findWhere, values } from 'underscore';

    import { generateRetailerId } from 'services/util';
    import adminService from 'services/admin';
    import mediaService from 'services/media';

    import Button from 'components/Button';

    import ORDERS_CONFIG from 'configs/orders';

    const { close } = getContext('simple-modal');

    export let order = {};

    let activities = [];

    const fileReader = new FileReader();

    let modal;
    let error;
    let tempImgFile;
    let tempImgUrl;
    let selectedCountry;
    let isLoading = false;

    onMount(async () => {
        if (order.id) {
            activities = await adminService.getOrderActivity(order.id);
        }
    });

    async function loadActivities() {
        activities = await adminService.getOrderActivity(order.id);
    }

    // Format timestamp
    function formatDate(timestamp) {
        if (!timestamp) return '';
        // Convert Firebase timestamp to JS Date
        const date = timestamp.toDate();
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date);
    }

    function formatState(stateId) {
        return values(ORDERS_CONFIG.states).find((state) => state.id == stateId);
    }

    function formatInternalState(internalStateId) {
        return values(ORDERS_CONFIG.internalStates).find((state) => state.id == internalStateId);
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.modal-title {
  background: #5123ad;
  padding: 1.5625em 2.5em;
}
.modal-title__title {
  margin-bottom: 0.625em;
  font-size: 1.5em;
  font-weight: 700;
  color: #fff;
}
.modal-title__desc {
  font-size: 0.875em;
  color: #fff;
}

.modal-content {
  margin: 1.25em 0;
  padding: 0 0.625em;
}

.form {
  margin: 0;
  padding: 0;
}

.form-content {
  position: relative;
  margin: 2.5em 0;
}
.form-content--compact {
  margin: 0.625em 0 1.25em 0;
}
.form-content__control {
  margin: 1.5625em 0;
}
.form-content__label {
  display: block;
  margin-bottom: 0.5em;
  font-size: 0.875em;
  font-weight: 700;
}
.form-content__label--checkbox {
  margin-bottom: 0;
  margin-left: 0.5em;
}
.form-content__helper {
  display: block;
  margin-bottom: 1em;
  font-size: 0.875em;
  color: #999999;
}
.form-content__input, .form-content__textarea {
  width: 100%;
  padding: 0.625em;
  outline: 0;
  border: 1px solid #999999;
  border-radius: 3px;
  font-size: 0.875em;
}
.form-content__select {
  width: 100%;
  font-size: 0.875em;
  --border: 1px solid #999999;
  --borderRadius: 0;
  --itemHoverBG: #f4f4f4;
  --itemIsActiveBG: #822c7d;
  --multiItemActiveBG: #166f86;
  --multiClearBG: #999999;
}
.form-content__textarea {
  min-height: 100px;
  line-height: 1.25;
}
.form-content__checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.error {
  margin: 1.5625em 0;
  font-weight: 700;
  color: #5123ad;
}

.actions {
  padding: 1.25em 1.5625em;
  background: #f4f4f4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}

.thumbnail {
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin: 0.625em auto;
  border: 2px solid #f4f4f4;
  transition: all 0.5s ease;
}
.thumbnail:hover {
  border-color: #5123ad;
}
.thumbnail__input {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.thumbnail__thumb {
  height: 100%;
}

.thumbnail-actions {
  display: flex;
  justify-content: flex-end;
  margin: 0.625em 0 1.25em 0;
}
.thumbnail-actions__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  color: #5123ad;
  border: 1px solid #5123ad;
  padding: 0 0.75em;
  background: transparent;
  border: none;
  font-size: 0.625em;
}
.thumbnail-actions__btn:hover {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05);
}
.thumbnail-actions__btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.thumbnail-actions__btn:hover {
  box-shadow: none;
  background: #5123ad;
  color: #fff;
}
.thumbnail-actions__btn:hover {
  background: transparent;
  color: #3d1a83;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.activity-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.activity-table th,
.activity-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #edf2f7;
}
.activity-table th {
  font-weight: 600;
  color: #4a5568;
  background-color: #f7fafc;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
}
.activity-table td {
  color: #2d3748;
  font-size: 0.875rem;
}
.activity-table tr:hover {
  background-color: #f7fafc;
}

.timestamp {
  color: #718096;
  white-space: nowrap;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.user-info .user-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e2e8f0;
}
.user-info .user-details .name {
  font-weight: 500;
}
.user-info .user-details .email {
  color: #718096;
  font-size: 0.75rem;
}

.state-change {
  display: flex;
  align-items: center;
  gap: 8px;
}
.state-change .state {
  font-weight: 500;
}
.state-change .arrow {
  color: #718096;
}

.type .automated {
  color: #718096;
  font-size: 0.75rem;
  margin-left: 4px;
}

.empty-state {
  text-align: center;
  color: #718096;
  padding: 32px !important;
}</style>

<div class="modal" bind:this={modal}>
    <form class="form">
        <div class="modal-title">
            <h2 class="modal-title__title">Order Activity</h2>
            <p class="modal-title__desc">{order.confirmationId}</p>
        </div>
        <div class="modal-content">
            {#if error}
                <p class="error animate__animated animate__fadeInDown">Uh oh! {error}</p>
            {/if}
            <div class="table-container">
                <table class="activity-table">
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>User</th>
                            <th>Change</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {#each activities as activity (activity.id)}
                            <tr>
                                <td class="timestamp">{formatDate(activity.timestamp)}</td>
                                <td>
                                    <div class="user-info">
                                        <div class="user-details">
                                            <div class="name">{activity.user.displayName || 'Unknown User'}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="state-change">
                                        <span class="state">
                                            {#if activity.type == 'internal_state_change'}
                                                {formatInternalState(activity.fromState) ? formatInternalState(activity.fromState).displayName : activity.fromState ? activity.fromState : 'N/A'}
                                            {:else if activity.type == 'state_change'}
                                                {formatState(activity.fromState) ? formatState(activity.fromState).displayName : activity.fromState}
                                            {:else}N/A{/if}
                                        </span>
                                        <span class="arrow">→</span>
                                        <span class="state">
                                            {#if activity.type == 'internal_state_change'}
                                                {formatInternalState(activity.toState) ? formatInternalState(activity.toState).displayName : activity.toState ? activity.toState : 'N/A'}
                                            {:else if activity.type == 'state_change'}
                                                {formatState(activity.toState) ? formatState(activity.toState).displayName : activity.toState}
                                            {:else}N/A{/if}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <span class="type">
                                        {activity.type == 'internal_state_change' ? 'Internal State Change' : 'State Change'}
                                        {#if activity.automated}
                                            <span class="automated">(Automated)</span>
                                        {/if}
                                    </span>
                                </td>
                            </tr>
                        {:else}
                            <tr>
                                <td colspan="4" class="empty-state">No activity found</td>
                            </tr>
                        {/each}
                    </tbody>
                </table>
            </div>
        </div>
        <div class="actions">
            <Button
                primary
                cancel
                onClick={(e) => {
                    e.preventDefault();
                    close();
                }}>
                Close
            </Button>
        </div>
    </form>
</div>
