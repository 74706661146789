<script>
    import { onMount, getContext } from 'svelte';
    import Select from 'svelte-select';
    import { findWhere } from 'underscore';

    import user from 'stores/user';

    import adminService from 'services/admin';
    import mediaService from 'services/media';

    import SHOP_CONFIG from 'configs/shop';
    import USERS_CONFIG from 'configs/users';

    import Button from 'components/Button';

    const { close } = getContext('simple-modal');

    export let product = {};
    export let retailers;
    export let selectedCountry;
    export let onSaveProduct;

    const fileReader = new FileReader();

    let modal;
    let error;
    let tempImgFile;
    let tempImgUrl;
    let selectedRetailer;
    let selectedCategory;
    let keywords;
    let isLoading = false;

    onMount(() => {
        fileReader.addEventListener('load', (event) => {
            tempImgUrl = event.target.result;
        });

        keywords = getKeywords();

        selectedRetailer = findWhere(retailers, { value: product.retailerId });
        selectedCategory = findWhere(SHOP_CONFIG.filters.options.categories, { value: product.category });
    });

    async function handleSaveProduct(e) {
        e.preventDefault();
        error = null;

        if (!isValid()) {
            modal.parentElement.scrollTo(0, 0);
            error = 'Please fill in all of the required fields before submitting.';
            return;
        }

        isLoading = true;

        if (tempImgFile) {
            mediaService
                .upload(
                    `products/${selectedRetailer.value}`,
                    `${product.code}-${Math.random().toString(36).substr(2, 6).toUpperCase()}`,
                    'jpg',
                    tempImgFile,
                )
                .then(
                    (url) => {
                        product.imageUrl = url; // Update the URL referenced by the image
                        saveProduct();
                    },
                    (err) => {
                        console.error('Error', err);
                    },
                )
                .catch((err) => {
                    console.log(err);
                    error = err.message;
                });
        } else {
            saveProduct();
        }
    }

    function saveProduct() {
        setKeywords();
        product.retailerId = selectedRetailer.value;
        product.category = selectedCategory.value;

        let promise = product.id ? adminService.updateProduct(product) : adminService.addProduct(product);

        promise
            .then(
                () => {
                    onSaveProduct(product);
                    close();
                },
                (err) => {
                    console.log(err);
                    error = err.message;
                },
            )
            .finally(() => {
                isLoading = false;
            });
    }

    function isValid() {
        return (
            product.name &&
            selectedRetailer &&
            selectedCategory &&
            product.price &&
            product.code &&
            product.desc &&
            product.specs
        );
    }

    function handleUploadThumbnail(event) {
        tempImgFile = event.target.files[0];
        fileReader.readAsDataURL(tempImgFile);
    }

    function getKeywords() {
        return product.metadata && product.metadata.keywords ? product.metadata.keywords.join(', ') : null;
    }

    function setKeywords() {
        if (!product.metadata) {
            product.metadata = {
                countryId: selectedCountry.value,
            };
        }

        if (!keywords) {
            product.metadata.keywords = [];
        } else {
            let normalizedKeywords = keywords.trim();

            product.metadata.keywords = keywords.length
                ? normalizedKeywords
                      .toLowerCase()
                      .split(',')
                      .map((item) => {
                          return item.trim();
                      })
                : [];
        }
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.modal-title {
  background: #5123ad;
  padding: 1.5625em 2.5em;
}
.modal-title__title {
  margin-bottom: 0.625em;
  font-size: 1.5em;
  font-weight: 700;
  color: #fff;
}
.modal-title__desc {
  font-size: 0.875em;
  color: #fff;
}

.modal-content {
  margin: 1.25em 0 3.75em 0;
  padding: 0 2.5em;
}

.form {
  margin: 0;
  padding: 0;
}

.form-content {
  position: relative;
  margin: 2.5em 0;
}
.form-content--compact {
  margin: 0.625em 0 1.25em 0;
}
.form-content__control {
  margin: 1.5625em 0;
}
.form-content__label {
  display: block;
  margin-bottom: 0.5em;
  font-size: 0.875em;
  font-weight: 700;
}
.form-content__label--checkbox {
  margin-bottom: 0;
  margin-left: 0.5em;
}
.form-content__helper {
  display: block;
  margin-bottom: 1em;
  font-size: 0.875em;
  color: #999999;
}
.form-content__input, .form-content__textarea {
  width: 100%;
  padding: 0.625em;
  outline: 0;
  border: 1px solid #999999;
  border-radius: 3px;
  font-size: 0.875em;
}
.form-content__select {
  width: 100%;
  font-size: 0.875em;
  --border: 1px solid #999999;
  --borderRadius: 0;
  --itemHoverBG: #f4f4f4;
  --itemIsActiveBG: #822c7d;
  --multiItemActiveBG: #166f86;
  --multiClearBG: #999999;
}
.form-content__textarea {
  min-height: 100px;
  line-height: 1.25;
}
.form-content__checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.error {
  margin: 1.5625em 0;
  font-weight: 700;
  color: #5123ad;
}

.actions {
  padding: 1.25em 1.5625em;
  background: #f4f4f4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}

.thumbnail {
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 400px;
  height: 300px;
  margin: 0.625em auto;
  border: 2px solid #f4f4f4;
  transition: all 0.5s ease;
}
.thumbnail:hover {
  border-color: #5123ad;
}
.thumbnail__input {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.thumbnail__thumb {
  height: 100%;
}

.thumbnail-actions {
  display: flex;
  justify-content: flex-end;
  margin: 0.625em 0 1.25em 0;
}
.thumbnail-actions__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.625em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.875em;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  color: #5123ad;
  border: 1px solid #5123ad;
  padding: 0 0.75em;
  background: transparent;
  border: none;
  font-size: 0.625em;
}
.thumbnail-actions__btn:hover {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05);
}
.thumbnail-actions__btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.thumbnail-actions__btn:hover {
  box-shadow: none;
  background: #5123ad;
  color: #fff;
}
.thumbnail-actions__btn:hover {
  background: transparent;
  color: #3d1a83;
}</style>

<div class="modal" bind:this={modal}>
    <form class="form">
        <div class="modal-title">
            <h2 class="modal-title__title">{product.id ? 'Edit' : 'Add New'} Product</h2>
            <p class="modal-title__desc">
                {#if product.id}
                    Please note that all of your changes to this Product will be visbile to customers in real-time.
                {:else}Please note that once added this product will be visible to customers in real-time.{/if}
            </p>
        </div>
        <div class="modal-content">
            {#if error}
                <p class="error animate__animated animate__fadeInDown">Uh oh! {error}</p>
            {/if}
            <div class="form-content">
                <div class="form-content__control">
                    <label class="form-content__label">Image</label>
                    <p class="form-content__helper">
                        Click the thumbnail to upload a new image. New images will not persist until the Product is
                        saved. Please ensure that all images are 400px wide by 300px tall (4:3 aspect ratio).
                    </p>
                    <div class="thumbnail">
                        <input
                            required
                            on:change={handleUploadThumbnail}
                            class="thumbnail__input"
                            type="file"
                            multiple="false"
                            accept="image/png, image/jpg, image/jpeg" />
                        <img
                            alt="thumbnail"
                            src={tempImgUrl || product.imageUrl || '/images/default_product@2x.jpg'}
                            class="thumbnail__thumb animate__animated animate__fadeIn" />
                    </div>
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Name</label>
                    <input required name="name" bind:value={product.name} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Retailer</label>
                    <div class="form-content__select">
                        <Select
                            required
                            items={retailers}
                            placeholder="Select Retailer"
                            bind:selectedValue={selectedRetailer}
                            showIndicator={true}
                            isClearable={false}
                            showChevron={true} />
                    </div>
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Category</label>
                    <div class="form-content__select">
                        <Select
                            required
                            items={SHOP_CONFIG.filters.options.categories}
                            placeholder="Select Category"
                            bind:selectedValue={selectedCategory}
                            showIndicator={true}
                            isClearable={false}
                            showChevron={true} />
                    </div>
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Code</label>
                    <input required name="code" bind:value={product.code} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <div class="form-content__checkbox">
                        <input type="checkbox" bind:checked={product.inStock} />
                        <label class="form-content__label form-content__label--checkbox">In Stock</label>
                    </div>
                </div>
                {#if $user.profile.role != USERS_CONFIG.roles.retailerAdmin}
                    <div class="form-content__control">
                        <div class="form-content__checkbox">
                            <input type="checkbox" bind:checked={product.isFeatured} />
                            <label class="form-content__label form-content__label--checkbox">Is Featured</label>
                        </div>
                    </div>
                {/if}
                <div class="form-content__control">
                    <label class="form-content__label">Specs</label>
                    <input required name="specs" bind:value={product.specs} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Description</label>
                    <textarea required class="form-content__textarea" bind:value={product.desc} />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Keywords</label>
                    <div class="form-content__helper">
                        All keywords should be lower-case and comma-separated (i.e. keyword 1, keyword 2, keyword 3,
                        etc.).
                    </div>
                    <textarea class="form-content__textarea" bind:value={keywords} />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Price</label>
                    <input required name="price" bind:value={product.price} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Sale Price</label>
                    <input name="salePrice" bind:value={product.salePrice} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Retailer Price</label>
                    <input
                        name="salePrice"
                        bind:value={product.retailerPrice}
                        type="text"
                        class="form-content__input" />
                </div>
            </div>
        </div>
        <div class="actions">
            <Button
                primary
                cancel
                onClick={(e) => {
                    e.preventDefault();
                    close();
                }}>
                Cancel
            </Button>
            <Button primary onClick={handleSaveProduct} disabled={isLoading}>
                {#if isLoading}Processing ...{:else}{product.id ? 'Update' : 'Add'} Product{/if}
            </Button>
        </div>
    </form>
</div>
