<script>
    import { onMount } from 'svelte';
    import { tweened } from 'svelte/motion';
    import { cubicOut } from 'svelte/easing';
    import Select from 'svelte-select';

    import ORDERS_CONFIG from 'configs/orders';

    import shoppingCart from 'stores/shoppingCart';
    import user from 'stores/user';

    import { getLocationsByCountryId } from 'services/user';

    import { formatCurrency } from 'services/util';
    import { calculateLoan } from 'services/loan';

    let totalInterestCharge = tweened($shoppingCart.loan.totalInterestCharge || 0, {
        duration: 400,
        easing: cubicOut,
    });

    let monthlyPaymentAmount = tweened($shoppingCart.loan.monthlyPayment || 0, {
        duration: 400,
        easing: cubicOut,
    });

    let paymentType = ORDERS_CONFIG.paymentTypes.finance;
    let firstName, lastName, emailAddress, phoneNumber, employer;
    let isExistingCustomer = false;
    let address = {};

    let locations = [];
    let numPeriodsAvailable = 36;
    let numPeriods = 12;

    $: {
        shoppingCart.setCustomer({
            firstName,
            lastName,
            emailAddress,
            employer,
            phoneNumber,
            address,
            isExistingCustomer,
        });

        shoppingCart.setPaymentType(paymentType);
    }

    onMount(() => {
        calculate();
        loadLocations();
    });

    function handlePeriodSelect(numPeriodsSelected) {
        numPeriods = numPeriodsSelected;
        calculate();
    }

    function handlePaymentTypeSelect(pt) {
        paymentType = pt;
        calculate();
    }

    function loadLocations() {
        getLocationsByCountryId($user.country.id).then((data) => {
            locations = data.map((location) => {
                return {
                    label: location.displayName,
                    value: location.id,
                    deliveryFee: location.deliveryFee,
                };
            });
        });
    }

    function handleLocationSelect(event) {
        let data = event.detail;

        shoppingCart.setDelivery(true, data.deliveryFee);
        address.location = {
            displayName: data.label,
            id: data.value,
        };
    }

    function calculate() {
        // Note: Total Cost DOES NOT include Delivery Fee or Interest Fees
        // let totalCost = $shoppingCart.totalCost + ($shoppingCart.delivery.isIncluded ? $shoppingCart.delivery.fee : 0);
        let totalCost = $shoppingCart.totalCost;
        shoppingCart.setLoan(calculateLoan($user.country, paymentType, totalCost, numPeriods));
        totalInterestCharge.set($shoppingCart.loan.totalInterestCharge);
        monthlyPaymentAmount.set($shoppingCart.loan.monthlyPaymentAmount);
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.checkout {
  margin: 0;
  padding: 1.5625em;
}

.form {
  margin: 0;
  padding: 0;
}

.group {
  width: 100%;
  margin-bottom: 2.5em;
}

.group-heading {
  margin-bottom: 1.5625em;
  padding-bottom: 0.625em;
  border-bottom: 2px solid #f5f4ff;
}
.group-heading__title {
  margin-bottom: 0.625em;
  font-size: 1.125em;
  font-weight: 700;
  color: #000;
}
.group-heading__subtitle {
  font-size: 0.875em;
  color: #999999;
}

.sub-group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.control {
  margin: 1.875em 0;
}
.control__label {
  display: block;
  font-size: 1em;
}
.control__big {
  margin: 0.5em 0;
  font-size: 2em;
  font-weight: 700;
  color: #5123ad;
}

.field {
  margin-bottom: 1.5625em;
}
.field--split {
  width: 100%;
}
.field__label {
  display: block;
  margin-bottom: 0.5em;
  font-size: 0.875em;
}
.field__input {
  width: 100%;
  border: 2px solid #f5f4ff;
  padding: 0.9375em 0.625em;
  border-radius: 4px;
  outline: 0;
  font-size: 0.875em;
  font-weight: 700;
  transition: all 0.25s ease;
}
@media (min-width: 600px) {
  .field__input {
    width: 75%;
  }
  .field__input--split {
    width: 95%;
  }
}
@media (min-width: 900px) {
  .field__input {
    width: 50%;
  }
  .field__input--split {
    width: 95%;
  }
}
.field__input:hover {
  border: 2px solid #5123ad;
}
.field__select {
  width: 50%;
  font-size: 0.875em;
  --border: 2px solid #e8eae9;
  --borderRadius: 4px;
  --itemHoverBG: #f4f4f4;
  --itemIsActiveBG: #822c7d;
  --multiItemActiveBG: #166f86;
  --multiClearBG: #999999;
}
.field__checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}
.field__checkbox div {
  margin-left: 0.5em;
}

.radio-buttons {
  margin-top: 1.5625em;
}
.radio-buttons__rb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.625em;
  padding: 0.625em;
  border: 2px solid #f5f4ff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.radio-buttons__rb--active, .radio-buttons__rb:hover {
  border: 2px solid #5123ad;
}
.radio-buttons__label {
  cursor: pointer;
  display: block;
}
.radio-buttons__title {
  margin-bottom: 0.3125em;
  font-size: 1em;
}
.radio-buttons__desc {
  font-size: 0.75em;
  color: #999999;
}
.radio-buttons__btn {
  display: inline-block;
  margin-right: 0.9375em;
}

.periods {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  gap: 0.625em 0.3125em;
  margin: 1.5625em 0;
}
@media (min-width: 600px) {
  .periods {
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  }
}
.periods__period {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  font-size: 0.875em;
  color: #999999;
  text-align: center;
  cursor: pointer;
}
@media (min-width: 600px) {
  .periods__period {
    height: 40px;
    font-size: 1.125em;
  }
}
.periods__period:hover {
  background: #f4f4f4;
  -webkit-animation: pulse;
          animation: pulse;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}
.periods__period--selected {
  background: #5123ad;
  font-weight: 700;
  color: #fff;
}
.periods__period--selected:hover {
  background: #5123ad;
}

.payments {
  overflow: hidden;
  margin: 1.5625em 0;
}

.payments-table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}
.payments-table__thead {
  border-bottom: 2px solid #f5f4ff;
}
.payments-table__th {
  padding: 0.9375em 0;
  font-size: 0.875em;
  font-weight: 700;
  text-align: left;
}
.payments-table__tr {
  border-bottom: 1px solid #f5f4ff;
}
.payments-table__td {
  padding: 0.9375em 0;
  font-size: 0.75em;
  color: #999999;
}
.payments-table__td--payment {
  color: #5123ad;
  font-weight: 700;
}

.split {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 600px) {
  .split {
    flex-flow: row nowrap;
  }
}</style>

<div class="checkout">
    <form name="form">
        <div class="group">
            <div class="group-heading">
                <div class="group-heading__title">Choose your payment type</div>
                <div class="group-heading__subtitle">Pay for your products by credit card or financing.</div>
            </div>
            <div class="control">
                <label class="control__label">How would you like to pay?</label>
                <div class="radio-buttons">
                    <div
                        on:click={() => handlePaymentTypeSelect(ORDERS_CONFIG.paymentTypes.finance)}
                        class="radio-buttons__rb"
                        class:radio-buttons__rb--active={paymentType == ORDERS_CONFIG.paymentTypes.finance}>
                        <img
                            alt="payment type icon"
                            class="radio-buttons__btn"
                            src={paymentType == ORDERS_CONFIG.paymentTypes.finance ? '/images/checkmark_circle_icon.svg' : '/images/empty_circle_icon.svg'}
                            height="30" />
                        <label class="radio-buttons__label">
                            <div class="radio-buttons__title">Finance it!</div>
                            <div class="radio-buttons__desc">Pay over time with Term Finance</div>
                        </label>
                    </div>
                    <div
                        on:click={() => handlePaymentTypeSelect(ORDERS_CONFIG.paymentTypes.creditCard)}
                        class="radio-buttons__rb"
                        class:radio-buttons__rb--active={paymentType == ORDERS_CONFIG.paymentTypes.creditCard}>
                        <img
                            alt="payment type icon"
                            class="radio-buttons__btn"
                            src={paymentType == ORDERS_CONFIG.paymentTypes.creditCard ? '/images/checkmark_circle_icon.svg' : '/images/empty_circle_icon.svg'}
                            height="30" />
                        <label class="radio-buttons__label">
                            <div class="radio-buttons__title">Cash, Credit Card or Visa Debit</div>
                            <!-- <div class="radio-buttons__desc">FCB, RBC and Scotiabank cards accepted</div> -->
                        </label>
                    </div>
                </div>
            </div>
        </div>
        {#if paymentType == ORDERS_CONFIG.paymentTypes.finance}
            <div class="group">
                <div class="group-heading">
                    <div class="group-heading__title">Choose your payment plan</div>
                    <div class="group-heading__subtitle">
                        Convert your purchases into loans and spread your payments over time.
                    </div>
                </div>
                <div class="control">
                    <label class="control__label">Over how many months would you like to finance your purchase?</label>
                    <div class="periods">
                        {#each Array(numPeriodsAvailable) as _, i}
                            <div
                                class="periods__period"
                                class:periods__period--selected={i + 1 == numPeriods}
                                on:click={() => {
                                    handlePeriodSelect(i + 1);
                                }}>
                                {i + 1}
                            </div>
                        {/each}
                    </div>
                </div>
            </div>
            <div class="group">
                <div class="group-heading">
                    <div class="group-heading__title">Monthly Payment</div>
                    <div class="group-heading__subtitle">
                        Your calculated monthly payment based on the number of months you selected.
                    </div>
                </div>
                <div class="control">
                    <div class="control__big">
                        {formatCurrency($monthlyPaymentAmount, $user.country.id, $user.country.currencyCode)}
                    </div>
                    <div class="control__label">
                        {numPeriods} monthly payment(s) starting in
                        <strong>{$shoppingCart.loan.startingMonth}</strong>
                        and ending in
                        <strong>{$shoppingCart.loan.endingMonth}</strong>
                    </div>
                </div>
            </div>
        {/if}
        <div class="group">
            <div class="group-heading">
                <div class="group-heading__title">Customer Details</div>
                <div class="group-heading__subtitle">Please tell us a little bit about yourself.</div>
            </div>
            <div class="control">
                <div class="split">
                    <div class="field field--split">
                        <label class="field__label field__label--split">First Name</label>
                        <input class="field__input field__input--split" name="firstName" bind:value={firstName} />
                    </div>
                    <div class="field field--split">
                        <label class="field__label field__label--split">Last Name</label>
                        <input class="field__input field__input--split" name="lastName" bind:value={lastName} />
                    </div>
                </div>
                <div class="split">
                    <div class="field field--split">
                        <label class="field__label field__label--split">Email Address</label>
                        <input class="field__input field__input--split" name="emailAddress" bind:value={emailAddress} />
                    </div>
                    <div class="field field--split">
                        <label class="field__label field__label--split">Phone Number</label>
                        <input
                            class="field__input field__input--split"
                            name="phoneNumber"
                            type="tel"
                            bind:value={phoneNumber} />
                    </div>
                </div>
                <div class="split">
                    <div class="field field--split">
                        <label class="field__label field__label--split">Employer</label>
                        <input class="field__input field__input--split" name="employer" bind:value={employer} />
                    </div>
                    <div class="field field--split">
                        <div class="field__checkbox">
                            <input
                                type="checkbox"
                                bind:checked={isExistingCustomer}
                                on:change={(event) => {
                                    shoppingCart.setCustomer({ ...$shoppingCart.customer, isExistingCustomer });
                                }} />
                            <div>I am an existing Term Finance customer</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="group">
            <div class="group-heading">
                <div class="group-heading__title">Customer Address</div>
                <div class="group-heading__subtitle">This information will be used to calculate delivery fees.</div>
            </div>
            <div class="control">
                <div class="field">
                    <label class="field__label">Street</label>
                    <input class="field__input" name="street" bind:value={address.street} />
                </div>
                <div class="field">
                    <label class="field__label">City/Town</label>
                    <div class="field__select">
                        <Select
                            items={locations}
                            on:select={handleLocationSelect}
                            placeholder="Select Location"
                            showChevron={true} />
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
