<script>
    import Container from 'components/Container';
    import Footer from 'components/Footer';
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.page {
  margin-top: 4.375em;
}

.content {
  position: relative;
  min-height: 100vh;
  z-index: 1;
  margin-bottom: 6.25em;
}
@media (min-width: 1200px) {
  .content {
    margin-top: -11.875em;
  }
}</style>

<div class="page">
    <slot name="header" />
    <div class="content">
        <Container>
            <slot name="content" />
        </Container>
    </div>
</div>
<Footer />
