<script>
    export let value = 0;
    export let onIncrement = () => {};
    export let onDecrement = () => {};
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
.counter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  border: 1px solid #f5f4ff;
}

.control {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  background: #f4f4f4;
  font-size: 1.125em;
  color: #999999;
  transition: all 0.25s ease;
}
.control:hover {
  background: #5123ad;
  color: #fff;
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  background: #fff;
  border-right: 1px solid #f5f4ff;
  border-left: 1px solid #f5f4ff;
}</style>

<div class="counter">
    <div class="control" on:click={onDecrement}>-</div>
    <div class="input">{value}</div>
    <div class="control" on:click={onIncrement}>+</div>
</div>
